<template>
  <div :class="classList">
    <slot />
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  name: "FillLayout",
  props: {
    justify: {
      type: String,
      required: false,
      default: "center"
    },
    align: {
      type: String,
      required: false,
      default: "center"
    },
    bgColor: {
      type: String,
      required: false,
      default: null
    }
  },
  setup(props) {
    const classList = computed(() => {
      return [
        "flex flex-col flex-grow w-full h-full",
        `justify-${props.justify}`,
        `items-${props.align}`,
        props.bgColor ?? null
      ]
        .filter(c => c)
        .join(" ");
    });

    return { classList };
  }
};
</script>
