<template>
  <div
    role="list"
    class="flex flex-row flex-wrap items-center justify-center w-full"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "IconLinkList"
};
</script>
