<template>
  <div :class="classList" :style="styleList">
    <slot />
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  name: "RAvatar",
  props: {
    size: {
      type: String,
      required: false,
      default: "4"
    },
    color: {
      type: String,
      required: false,
      default: "#d3d3d3"
    }
  },
  setup(props) {
    const classList = computed(() => {
      return [
        "flex items-center justify-center rounded-full overflow-hidden",
        `w-${props.size}`,
        `h-${props.size}`
      ]
        .filter(c => c)
        .join(" ");
    });

    const styleList = computed(() => {
      return { backgroundColor: props.color };
    });

    return { classList, styleList };
  }
};
</script>
