<template>
  <FillLayout
    class="space-y-8 overflow-y-auto"
    justify="start"
    :style="page.theme"
  >
    <PageHeader :avatarUrl="page.header.avatarUrl" :title="page.header.title" />
    <main class="w-full max-w-screen-md">
      <LinkList>
        <LinkListItem
          v-for="(link, index) in publishedLinks"
          :key="link.id"
          :link="link"
          :styleList="page.theme.link"
          :tabindex="index + 1"
        />
      </LinkList>
    </main>
    <footer class="w-full max-w-screen-md">
      <IconLinkList>
        <IconLinkListItem
          v-for="(link, index) in page.iconLinks"
          :key="link.id"
          :link="link"
          :styleList="page.theme.iconLink"
          :tabindex="1000 + index"
        />
      </IconLinkList>
    </footer>
  </FillLayout>
</template>

<script>
import { computed } from "vue";
import FillLayout from "@/modules/core/layouts/FillLayout";
import PageHeader from "@/modules/pages/components/PageHeader";
import LinkList from "@/modules/pages/components/LinkList";
import LinkListItem from "@/modules/pages/components/LinkListItem";
import IconLinkList from "@/modules/pages/components/IconLinkList";
import IconLinkListItem from "@/modules/pages/components/IconLinkListItem";

export default {
  name: "PageFragment",
  props: {
    page: {
      type: Object,
      required: true
    }
  },
  components: {
    FillLayout,
    PageHeader,
    LinkList,
    LinkListItem,
    IconLinkList,
    IconLinkListItem
  },
  setup(props) {
    const publishedLinks = computed(() => {
      return props.page.links?.filter(l => l.isPublished == true);
    });

    return { publishedLinks };
  }
};
</script>
