<template>
  <a
    role="link"
    rel="noopener"
    target="_blank"
    :aria-label="link.title"
    :href="link.url"
    :class="classList"
    :style="styleList"
  >
    <span>{{ link.title }}</span>
  </a>
</template>

<script>
import { computed } from "vue";

export default {
  name: "LinkListItem",
  props: {
    link: {
      type: Object,
      required: true
    },
    styleList: {
      type: Object,
      required: false
    }
  },
  setup(props) {
    const classList = computed(() => {
      return [
        "flex justify-center items-center w-full min-h-4 p-2 text-center transition ease-in-out duration-200 transform hover:scale-105 hover:animate-none focus:animate-none focus:scale-105 focus:outline-none",
        props.link.highlightEffect ? props.link.highlightEffect : null
      ]
        .filter(c => c)
        .join(" ");
    });

    return { classList };
  }
};
</script>
