<template>
  <a
    role="link"
    rel="noopener"
    target="_blank"
    :aria-label="link.title"
    :href="link.url"
    class="my-2 mx-4 transition ease-in-out duration-200 transform hover:scale-150 focus:scale-150 focus:outline-none"
  >
    <RIcon size="fa-3x" :name="link.iconName" :style="styleList" />
  </a>
</template>

<script>
import RIcon from "@/modules/core/components/RIcon";

export default {
  name: "IconLinkListItem",
  components: {
    RIcon
  },
  props: {
    link: {
      type: Object,
      required: true
    },
    styleList: {
      type: Object,
      required: false
    }
  }
};
</script>
