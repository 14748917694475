import { ref, readonly } from "vue";
import { pagesCollection } from "@/plugins/firebase";
import usePageDocumentMapper from "@/modules/core/compositions/usePageDocumentMapper";

export default function usePublicPageFetcher() {
  const { mapPageFromDocument } = usePageDocumentMapper();
  const page = ref(null);
  const error = ref(null);
  const isLoading = ref(false);

  const fetchPublicPage = async slug => {
    isLoading.value = true;
    error.value = null;

    try {
      let doc = await pagesCollection.doc(slug).get();

      if (doc.exists) {
        page.value = mapPageFromDocument(doc, false);
      } else {
        page.value = null;
      }
    } catch (err) {
      error.value = err;
    }

    isLoading.value = false;
  };

  return {
    page: readonly(page),
    error: readonly(error),
    isLoading: readonly(isLoading),
    fetchPublicPage
  };
}
