<template>
  <div role="list" class="flex flex-col w-full space-y-8">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "LinkList"
};
</script>
