<template>
  <header class="flex flex-col justify-center items-center space-y-4">
    <RAvatar size="28">
      <img v-if="avatarUrl" :src="avatarUrl" :alt="title" />
      <RIcon v-else size="fa-3x" name="fas fa-user" class="text-gray-600" />
    </RAvatar>
    <h2 class="text-2xl">{{ title }}</h2>
  </header>
</template>

<script>
import RAvatar from "@/modules/core/components/RAvatar";
import RIcon from "@/modules/core/components/RIcon";

export default {
  name: "PageHeader",
  components: {
    RAvatar,
    RIcon
  },
  props: {
    avatarUrl: {
      type: String,
      required: false
    },
    title: {
      type: String,
      required: false
    }
  }
};
</script>
