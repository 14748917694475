<template>
  <PageFragment class="py-8" :page="page.data" />
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import usePublicPageFetcher from "@/modules/pages/compositions/usePublicPageFetcher";
import PageFragment from "@/modules/pages/fragments/PageFragment";

export default {
  name: "FullPage",
  components: {
    PageFragment
  },
  async setup() {
    const { page, fetchPublicPage } = usePublicPageFetcher();
    const currentRoute = useRoute();
    const router = useRouter();

    await fetchPublicPage(currentRoute.params.slug);

    if (!page.value) {
      return router.push("/");
    }

    if (page.value.data.header.title) {
      document.title = page.value.data.header.title;
    }

    return { page };
  }
};
</script>
